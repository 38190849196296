import React, {FC} from "react";
import Grid from '@material-ui/core/Grid'
import {date} from "common-frontend-components";
import {appConfig} from "../../variables/appConfig";
import {FooterBackgroundContainer} from "../backgrounds/FooterBackgroundContainer";
import {ConsoleLog} from "react-console-log";
import {links} from "../../utils/links";
import {useFooterStyles} from "./useFooterStyles";
import {CustomLink} from "./components/CustomLink";
import {CustomLink2} from "./components/CustomLink2";
import {FooterContainer} from "./components/FooterContainer";
import {FooterGrid} from "./components/FooterGrid";

export const WwwFooter:FC = () => {
  const classes = useFooterStyles();

  return (
    <FooterBackgroundContainer>
      <FooterContainer>
        <FooterGrid>
          <Grid item className={classes.item}>
            <h3>{appConfig.app_name}</h3>
            <CustomLink onClick={links.go_to_about}>עלינו</CustomLink>
            <CustomLink onClick={links.go_to_privacy}>מדיניות פרטיות</CustomLink>
            <CustomLink onClick={links.go_to_terms}>תנאי שימוש</CustomLink>
          </Grid>

          <Grid container className={classes.RightsGrid}>
            <CustomLink2 onClick={links.go_to_privacy}>פרטיות</CustomLink2>
            <span className={classes.sep}>|</span>
            <CustomLink2 onClick={links.go_to_terms}>תנאי שימוש</CustomLink2>
            <span className={classes.sep}>|</span>
            <span>© {date.getFullYear()}, {appConfig.app_name}, {"בע\"מ או שלוחותיה. כל הזכויות שמורות."}</span>
          </Grid>

          <Grid item xs={12}>
            <ConsoleLog/>
          </Grid>
        </FooterGrid>
      </FooterContainer>
    </FooterBackgroundContainer>
  )
};

