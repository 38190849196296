import React, {FC, ReactNode, StrictMode} from 'react';
import {Switch, Redirect, useLocation, Route} from "react-router-dom";
import {TermsPage} from "../views/www/TermsPage";
import {PrivacyPage} from "../views/www/PrivacyPage";
import {ContactPage} from "../views/www/ContactPage";
import { routes } from "../variables/routes";
import {WeUseCookies} from "../components/popups/WeUseCookies"
import {
  useSizeListener,
  useScrollTransition,
  useSizes,
  BrowserRouterProvider,
  GoogleAnalytics4Provider
} from "common-frontend-components";
import {useUpdateVersion} from "common-frontend-components";
import {Backdrop, useLoadIpinfo} from "common-frontend-components";
import {useConnectRouterStore} from "common-frontend-components";
import {WwwHomePage} from "../views/www/WwwHomePage";
import * as serviceWorkerRegistration from "../serviceWorkerRegistration";
import reportWebVitals from "../reportWebVitals";
import {LanguageProvider} from "../components/providers/LanguageProvider";
import {appConfig} from "../variables/appConfig";

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const Providers:FC<{children:ReactNode}> = ({children}) => {
  return (
    <StrictMode>
      <BrowserRouterProvider>
        <LanguageProvider>
          {/*<GoogleAnalytics4Provider gaCode={appConfig.gaCode}>*/}
            {children}
          {/*</GoogleAnalytics4Provider>*/}
        </LanguageProvider>
      </BrowserRouterProvider>
    </StrictMode>
  )
}

const Hooks:FC<{children:ReactNode}> = ({children}) => {
  useConnectRouterStore();
  useLoadIpinfo();
  useSizeListener();
  useScrollTransition();
  useSizes();
  useUpdateVersion();

  return <>{children}</>;
}

const App:FC = () => {
  const { pathname } = useLocation();
  return (
    <>
      <Switch>
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />

        <Route path={routes.terms} exact component={TermsPage}/>
        <Route path={routes.privacy} exact component={PrivacyPage}/>
        <Route path={routes.contact} exact component={ContactPage}/>

        <Route exact path={routes.home} component={WwwHomePage}/>

        <Redirect from="*" to={routes.home} />
      </Switch>

      <WeUseCookies/>

      <Backdrop/>
    </>
  )
}

const WwwApp:FC = () => {
  return (
    <Providers>
      <Hooks>
        <App/>
      </Hooks>
    </Providers>
  );
}

export { WwwApp };