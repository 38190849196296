import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid'
import Slide from '@material-ui/core/Slide'
import {makeStyles} from "@material-ui/core";
import {appConfig} from "../../variables/appConfig"
import { Link } from "react-router-dom";
import { routes } from "../../variables/routes";
import {
  useCheckAreCookiesEnabled,
  useCookiesEnabled,
  onAcceptingPrivacyPolicy,
  usePrivacyPolicyAccepted, boxShadows,
} from "common-frontend-components";
import { useLocation } from "react-router-dom";
import { useSM } from "common-frontend-components";

export const useStyles = makeStyles({
  root: {
    position: 'fixed',
    zIndex: 2147483645,
    width: "100%",
    bottom: 0,
    padding: '1.25em 1.25em 1em 2em',

    fontFamily: 'Segoe UI, Segoe UI Midlevel, Segoe WP, Arial, Sans-Serif',
    fontSize: 16,
    boxShadow: boxShadows.boxShadowCookies,

    maxHeight: '90%',
    overflowX: 'hidden',
    overflowY: 'auto',
    backgroundColor: '#FFFFFF',
  },

  paper: {
    fontFamily: "inherit",
    fontWeight: "normal",
    WebkitFontSmoothing: "auto",
    letterSpacing: "normal",
    lineHeight: "normal",
    height: "auto",
    minHeight: "0",
    maxHeight: "none",
    minWidth: "0",
    borderRadius: "0",
    border: "none",
    clear: "none",
    float: "none",
    bottom: "auto",
    left: "auto",
    right: "auto",
    top: "auto",
    textAlign: "left",
    textDecoration: "none",
    textIndent: "0",
    textShadow: "none",
    textTransform: "none",
    whiteSpace: "normal",
    background: "none",
    overflow: "visible",
    verticalAlign: "baseline",
    visibility: "visible",
    zIndex: "auto",
    boxShadow: "none",
    position: "relative",
    maxWidth: "100%",
    margin: "0 auto",
    boxSizing: "border-box",
    padding: "0",
    width: "100%",

    '& h3': {
      boxSizing: "content-box",
      WebkitFontSmoothing: "auto",
      letterSpacing: "normal",
      padding: "0",
      margin: "0",
      height: "auto",
      minHeight: "0",
      maxHeight: "none",
      minWidth: "0",
      maxWidth: "none",
      borderRadius: "0",
      border: "none",
      clear: "none",
      position: "static",
      bottom: "auto",
      left: "auto",
      right: "auto",
      top: "auto",
      textAlign: "left",
      textDecoration: "none",
      textIndent: "0",
      textShadow: "none",
      textTransform: "none",
      whiteSpace: "normal",
      background: "none",
      overflow: "visible",
      verticalAlign: "baseline",
      visibility: "visible",
      zIndex: "auto",
      boxShadow: "none",
      marginTop: "0",
      fontWeight: "600",
      fontFamily: "inherit",
      fontSize: "1.2em",
      lineHeight: "1.3",
      marginBottom: "10px",
      float: "left",
      color: "#696969",
      // width: "50%",
    },

    '& p': {
      boxSizing: "content-box",
      fontWeight: "normal",
      WebkitFontSmoothing: "auto",
      letterSpacing: "normal",
      padding: "0",
      height: "auto",
      minHeight: "0",
      maxHeight: "none",
      minWidth: "0",
      maxWidth: "none",
      borderRadius: "0",
      border: "none",
      position: "static",
      bottom: "auto",
      left: "auto",
      right: "auto",
      top: "auto",
      textDecoration: "none",
      textIndent: "0",
      textShadow: "none",
      textTransform: "none",
      whiteSpace: "normal",
      background: "none",
      overflow: "visible",
      verticalAlign: "baseline",
      visibility: "visible",
      zIndex: "auto",
      boxShadow: "none",
      margin: "0 0 1em 0",
      fontFamily: "inherit",
      clear: "both",
      textAlign: "left",
      float: "left",
      fontSize: ".813em",
      lineHeight: "1.5",
      color: "#696969",
      // width: "50%",
      borderRight: ({mobile}:any) => mobile ? "none" : "1px solid #d8d8d8",
      paddingRight: "1rem",
      marginBottom: "0",
      paddingBottom: "1em",
    }
  },

});

export const ignoreRoutes = [routes.terms, routes.privacy];

export const WeUseCookies = () => {
  const location = useLocation();

  useCheckAreCookiesEnabled();

  const cookiesEnabled = useCookiesEnabled();
  const privacyPolicyAccepted = usePrivacyPolicyAccepted();

  const mobile = useSM();

  const classes = useStyles({mobile});

  if (ignoreRoutes.includes(location.pathname))
    return null;

  if (privacyPolicyAccepted && cookiesEnabled)
    return null;

  if (cookiesEnabled===null)
    return null;

  return (
    <div className={classes.root}>
      <Slide direction="right" timeout={400} in={true} mountOnEnter unmountOnExit>
        <div className={classes.paper}>
          <Grid container spacing={2} style={{width:'100%'}}>
            {cookiesEnabled
              ?
              <>
                <Grid item style={{maxWidth: 600}}>
                  <h3>
                    We Care About Your Privacy
                  </h3>
                  <p>
                    {appConfig.app_name} and our third-party vendors use cookies{" "}
                    to store and access information such as unique IDs{" "}
                    to deliver, maintain and improve our services and ads.{" "}
                    If you agree, {appConfig.app_name} will personalize the content{" "}
                    and ads that you see. You can select ‘I Accept’ to{" "}
                    consent to these uses. Privacy Statement.
                  </p>
                </Grid>

                <Grid item {...(mobile ? {xs:12, style:{paddingTop:0}} : {style:{flexGrow: 1}})}>
                  <Grid container justify={mobile ? 'flex-start' : 'flex-end'}>
                    <Grid container justify={mobile ? 'flex-start' : 'center'} style={mobile?{}:{maxWidth: 300, width: '100%', marginTop: '2.1em'}}>
                      <Grid item xs={12}>
                        <Grid container justify={mobile ? 'flex-start' : 'center'}>
                          <Button variant="contained" color="primary" onClick={onAcceptingPrivacyPolicy} style={{marginBottom: '0.5em'}}>
                            I Accept
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container justify={mobile ? 'flex-start' : 'center'}>
                          <Link to={routes.privacy} className="btn btn-link" {...(mobile ? {style:{padding:3}} : {})}>Learn More</Link>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
              </>
              :
              <Grid item >
                <h3>
                  Cookies are disabled
                </h3>
                <p>
                  {appConfig.app_name} and our third-party vendors{" "}
                  use cookies to run some of our services,{" "}
                  please enable cookies and refresh{" "}
                  in order to use our services.
                </p>
              </Grid>
            }
          </Grid>
        </div>
      </Slide>
    </div>
  )
};