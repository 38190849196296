import React, {FC} from "react";
import {appConfig} from "../../variables/appConfig";
import {Spacer, useXS} from "common-frontend-components";
import {HeaderBackgroundContainer} from "../../components/backgrounds/HeaderBackgroundContainer";
import {FooterAtBottomContainer} from "../../components/footers/FooterAtBottomContainer";
import {WwwFooter} from "../../components/footers/WwwFooter";
import {makeStyles, Theme} from "@material-ui/core/styles";
// import Grid from "@material-ui/core/Grid";
// import Button from "@material-ui/core/Button";
// import {changeUrl} from "common-frontend-components";
// import {LandingPageLayout} from "../../components/pages/HotelsLanding/LandingPageLayout";

export const useWwwHomePageStyles = makeStyles<Theme, { isMobile: boolean }>({
  bigLogo: {
    width: "50%",
    height: "50%",
    maxWidth: 300,
    maxHeight: 300,
    minWidth: 70,
    minHeight: 70,
  },

  container: {
    minHeight: '100vh',
    padding: '0 0.5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& main': {
      padding: '5rem 0',
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },

    // '& footer': {
    //     width: 100%;
    //     height: 100px;
    //     border-top: 1px solid #eaeaea;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    // }

    '& code': {
      background: '#fafafa',
      borderRadius: '5px',
      padding: '0.75rem',
      fontSize: '1.1rem',
      fontFamily: 'Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace',
    }
  },

  description: {
    textAlign: 'center',
  },

  title: {
    textAlign: 'center',
    margin: 0,
    lineHeight: 1.15,
    fontSize: '4rem',
    '& a': {
      color: '#0070f3',
      textDecoration: 'none',
      '&:hover, &:focus, &:active': {
        textDecoration: 'underline',
      },
    },
  },

  grid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    maxWidth: '800px',
    marginTop: '3rem',
  }
});

export const WwwHomePage:FC = () => {
  const isMobile = useXS();
  const classes = useWwwHomePageStyles({isMobile});

  return (
  //  <HeaderBackgroundContainer>
      <FooterAtBottomContainer footer={<WwwFooter/>}>
        <div className={classes.container}>
          <main>
            <img src={appConfig.logo} className={classes.bigLogo} alt=""/>
            <Spacer size={20}/>
            <h1 className={classes.title} style={isMobile?{fontSize:30}:{}}>
              ברוכים הבאים ל
            </h1>
            <h1 className={classes.title}>
              <a href="https://events.esemes.today" style={isMobile?{fontSize:37}:{fontSize: "5.1rem"}}>esemes</a>
            </h1>

            <p className={classes.description}>
              אנחנו בשלבי בנייה, ליצירת קשר:
            </p>
            <code>yonadav.barilan@gmail.com</code>

            <div className={classes.grid}>
              <a href="https://events.esemes.today" className="card">
                <h3>ניהול אירועים ←</h3>
                <p>ניהול אירועים, מפגשים וחתונות באופן דיגיטלי ומהנה</p>
              </a>

              <a href="https://events.esemes.today" className="card">
                <h3>שליחת הודעות ←</h3>
                <p>שליחת הודעות SMS, אישורי הגעה, תזכורות ועוד, הכל בלחיצה פשוטה</p>
              </a>

              <a href="https://events.esemes.today" className="card">
                <h3>מענה אוטומטי ←</h3>
                <p>
                  מענה קולי, שליחת אימיילים והודעות וואצאפ ורשתות חברתיות
                  <br/>
                  <br/>
                </p>
              </a>

              <a href="https://events.esemes.today" className="card">
                <h3>עלות ותועלת ←</h3>
                <p>
                  10% מהמוזמנים שלא יגיעו ירצו לשלם דרך האפליקציה.
                  אל תפספסו הזדמנות לחסוך אלפי שקלים
                </p>
              </a>
            </div>
          </main>

          {/*@ts-ignore*/}
          <style jsx>{`
            
        .card {
          margin: 1rem;
          flex-basis: 45%;
          padding: 1.5rem;
          text-align: start;
          color: inherit;
          text-decoration: none;
          border: 1px solid #eaeaea;
          border-radius: 10px;
          transition: color 0.15s ease, border-color 0.15s ease;
        }

        .card:hover,
        .card:focus,
        .card:active {
          color: #0070f3;
          border-color: #0070f3;
        }

        .card h3 {
          margin: 0 0 1rem 0;
          font-size: 1.5rem;
        }

        .card p {
          margin: 0;
          font-size: 1.25rem;
          line-height: 1.5;
        }

        .logo {
          height: 1.5em;
        }

        @media (max-width: 600px) {
          .grid {
            width: 100%;
            flex-direction: column;
          }
        }
      `}</style>

          {/*@ts-ignore*/}
          <style jsx global>{`
        html,
        body {
          padding: 0;
          margin: 0;
          font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
            Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
            sans-serif;
        }

        * {
          box-sizing: border-box;
        }
      `}</style>
        </div>
      </FooterAtBottomContainer>
    //</HeaderBackgroundContainer>
    // <LandingPageLayout>
    //   <Grid container justify={'center'} spacing={2} style={{paddingTop:150}}>
    //     <Grid item>
    //       <Button variant={'contained'} color={'primary'} onClick={()=>changeUrl("https://events.esemes.today")}>
    //         Go To The App
    //       </Button>
    //     </Grid>
    //
    //     <Grid item>
    //       <Button variant={'contained'} color={'secondary'} onClick={()=>changeUrl("https://events.esemes.today")}>
    //         I Own / Work in a Hotel
    //       </Button>
    //     </Grid>
    //   </Grid>
    // </LandingPageLayout>
  )
}