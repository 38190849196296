import React from 'react';
import { render } from 'react-dom';
import {ids} from "./variables/ids";

export const runApp = (App:React.FC):void => {
  render(
    <App/>
    ,document.getElementById(ids.root)
  );
};
