import React, {FC} from 'react'
import useStyles from '../../assets/jss/termStyles'
import {appConfig} from "../../variables/appConfig";


export const PrivacyPage:FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.termContainer}>
      <div className={classes.termBox}>
        {/*@ts-ignore*/}
        <center>
          <h1 className={classes.termTitle}>Privacy Policy</h1>
          <p>
            <a href={'https://'+appConfig.website}><span className={classes.termText}>{appConfig.website}</span></a>
            <span className={classes.termText}> - &nbsp;Privacy Policy</span></p>
        {/*@ts-ignore*/}
        </center>
        <br/>
        <br/>
        <p>
          <span className={classes.termText}>This Privacy Policy was last modified on April 16</span>
          <span className={classes.termText}>th</span><span className={classes.termText}> 2019</span></p>
        <p>
          <span className={classes.termText}>{appConfig.company} operates </span><a href={'https://'+appConfig.website}><span className={classes.termText}>{appConfig.website}</span></a>
          <span className={classes.termText}> (the "site"). This page informs you of our policies regarding the collection, use and disclosure of Personal information we receive from users of the site.</span>
        </p>
        <p><span className={classes.termText}>We use your personal Information only for providing and improving the Site. By using the Site, you agree to the collection and use of information in accordance with this policy.</span></p>
        <p><span className={classes.termText}>Information Collection and use</span></p>
        <p><span className={classes.termText}>While using our site. We may ask you to provide us with certain personally information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to your name ("Personal Information").</span></p>
        <p><span className={classes.termText}>Log Data</span></p>
        <p><span className={classes.termText}>Like many sites operators, we collect information that your browser sends whenever you visit our Site ("Log Data"). This Log Data may include information such as your computers' Internet Protocol ("IP") address, browser type, browser version, and the pages of our site that you visit. The time and date of your visit' the time spent on those pages and other statistics. &nbsp;</span></p>
      </div>
    </div>
  );
}