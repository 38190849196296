import React, {FC, ReactNode} from "react";
import {
  setDefaultLanguage,
  languageMap,
  RTL,
  useIsLanguageSelected
} from "common-frontend-components";

setDefaultLanguage(languageMap["heb"]);

export const LanguageProvider:FC<{children:ReactNode}> = ({children}) => {
  const isLanguageSelected = useIsLanguageSelected();

  if (!isLanguageSelected)
    return null;

  return (
    <RTL children={children}/>
  )
}