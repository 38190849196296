import React from "react";
import {useFooterStyles} from "../useFooterStyles";
import Link from "@material-ui/core/Link";

export const CustomLink = ({onClick, children}: { onClick: () => void, children: React.ReactNode }) => {
  const classes = useFooterStyles();
  return (
    <Link className={classes.link} component="button" variant="body2" onClick={onClick}>
      {children}
    </Link>
  )
};