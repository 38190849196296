import React, {FC} from 'react'
import useStyles from '../../assets/jss/termStyles'
import {appConfig} from "../../variables/appConfig";

export const ContactPage:FC = () => {
  const classes = useStyles();
  return (
      <div className={classes.termContainer}>
        <div className={classes.termBox}>
          <h1 className={classes.termTitle}>Contact Us</h1>
          <div style={{width: 200, margin:'auto'}}>
            <p>
              Company: {appConfig.company}
            </p>
            <p>
              website: {appConfig.website}
            </p>
            <p>
              email: contact@{appConfig.website}
            </p>
            <p>
              phone: +972 xx-xxx-xxx
            </p>
            <p>
              location: Israel, xxx
            </p>
          </div>
        </div>
      </div>
  );
}