import { routes } from '../variables/routes'
import {history} from "common-frontend-components";
import {links as baseLinks} from "common-frontend-components";

export const links = {
  ...baseLinks,

  go_to_landing_page: ():void =>
    history.push(routes.landing_page),

  ///////////////
  // www links //
  ///////////////

  go_to_keep_in_touch: ():void =>
    history.push(routes.keep_in_touch),

  ///////////////
  // app links //
  ///////////////


};