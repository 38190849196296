import React, {FC, ReactNode} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {usePleaseWait} from "../../stores/common/usePleaseWaitStore";

const useStyles = makeStyles({
  FooterAtBottomContainer: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },

  autoMargin: {
    marginTop: "auto"
  }
});

export interface IFooterAtBottom {
  footer: ReactNode;
}

export const FooterAtBottom: FC<IFooterAtBottom> = ({footer}) => {
  const classes = useStyles();
  return (
    <div className={classes.autoMargin}>
      {footer}
    </div>
  )
}

export interface IFooterAtBottomContainer {
  children: ReactNode;
  footer: ReactNode;
}

export const FooterAtBottomContainer: FC<IFooterAtBottomContainer> = ({children, footer}) => {
  const classes = useStyles();
  return (
    <div className={classes.FooterAtBottomContainer}>
      {children}
      <FooterAtBottom footer={footer}/>
    </div>
  )
}