import {makeStyles} from "@material-ui/core/styles";
import React, {FC, ReactNode} from "react";
import {useXS, uuid} from "common-frontend-components";
import {darkPurple, lightPurple} from "../../assets/styles/colors";

const useBackgroundContainerStyles = makeStyles({
  svgWaverContainer: {
    display: 'inline-block',
    position: 'relative',
    width: '100%',
    verticalAlign: 'middle',
  },

  svgWaver: {
    display: 'inline-block',
    position: 'absolute',
    top: '0',
    left: '0',
    transform: 'rotate(180deg)',
    animation: "$fadeIn .9s ease-in-out",
  },

  childrenContainer: {
    position: 'relative',
  },

  "@keyframes fadeIn": {
    from: {
      transform: 'scale(0) translateX(500px)',
      borderRadius: '50%',
    },
    to: {
      transform: 'scale(1) translateX(0px)',
      borderRadius: 'none',
    },
  },
});

const linearGradientId = `FooterBackgroundContainer-grad-${uuid()}`;
const fill = `url(#${linearGradientId})`;

export const FooterBackgroundContainer:FC<{children:ReactNode}> = ({ children }) => {
  const classes = useBackgroundContainerStyles();
  const isMobile = useXS();

  return (
    <>
      <div className={classes.svgWaverContainer}>
        <svg viewBox={`0 0 500 ${isMobile ? 700 : 312}`} preserveAspectRatio="xMinYMin meet" className={classes.svgWaver}>
          <defs>
            <linearGradient id={linearGradientId} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" style={{stopColor:darkPurple, stopOpacity:1}} />
              <stop offset="100%" style={{stopColor:lightPurple ,stopOpacity:1}} />
            </linearGradient>
          </defs>

          <path d={isMobile ? "M0,700 C900,610 350,200 850,650 L500,00 L0,0 Z" : "M0,310 C400,330 650,0 550,150 L500,00 L0,0 Z"} style={{stroke: 'none', fill}}/>
        </svg>
      </div>

      <div className={classes.childrenContainer}>
        {children}
      </div>
    </>
  )
};