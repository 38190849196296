import {makeStyles} from "@material-ui/core/styles";

export const useFooterStyles = makeStyles({
  footer: {
    // background: backgroundColor,
    color: 'white',
    minHeight: 64,
  },

  root: {
    flexGrow: 1,
  },

  Grid: {
    maxWidth: 1200,
    margin: 'auto',
    padding: 20,
    paddingTop: 40,
    paddingBottom: 80,
    '& h3': {
      color: '#e0ecff',
    }
  },

  item: {
    width: '50%',
    minWidth: 300,
    padding: 20,
  },

  itemSocial: {
    minWidth: 300,
    padding: 20,
    width: '100%',
    paddingLeft: 12,
    paddingBottom: 0,
  },

  GridSocial: {
    paddingLeft: 7,
  },

  link: {
    color: 'white',
    width: '100%',
    textAlign: 'start',
    '&:hover': {
      color: 'rgba(255,255,255,0.65)',
    }
  },

  link2: {
    color: 'white',
    textAlign: 'start',
    '&:hover': {
      color: 'rgba(255,255,255,0.65)',
    }
  },

  RightsGrid: {
    padding: 20,
    marginTop: 20,
  },

  sep: {
    marginLeft: 10,
    marginRight: 10,
  }
});