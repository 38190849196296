import logo from "../assets/images/logo.png";
import {setAppConfig} from "common-frontend-components";

const apiUrl = process.env.REACT_APP_STAGE === 'local' ? "http://localhost:4000/dev" : "https://api.esemes.today";

export const appConfig = setAppConfig({
  apiUrl,
  accountUrl: `${apiUrl}/accounts`,
  wwwUrl: `${apiUrl}/www`,
  versionUrl: `https://esemes-version.s3.amazonaws.com/version.json`,
  reCaptchaSiteKey: '6LfOq8EaAAAAAE0Ly4FiggXn5FjKAwiDYJBHTnpk',
  googleLoginClientId: '544695674954-023uldt61hr6hq0jpv3l9ak91gcm2g5u.apps.googleusercontent.com',
  google_maps_key: 'AIzaSyB_ZjKlnfMiVB8O9cFheLmoiAbBmXNBKdM',
  ipinfo_token: "35b5a926a88486",
  company: 'esemes Ltd',
  website: 'esemes.today',
  app_name: 'esemes',
  app_slogan: 'Get The Best SMS Experience',
  app_description: 'send sms messages with ease',
  terms_last_update: "April 16 2019",
  desktopMinWidth: 1200,
  logo,
  googleAnalyticsTrackingId: "UA-163373723-2",
  gaCode: "273260282",
})
