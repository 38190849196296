import React, {FC} from "react";
import Grid from '@material-ui/core/Grid'
import {useFooterStyles} from "../useFooterStyles";
import {GridTypeMap} from "@material-ui/core/Grid/Grid";

export const FooterGrid:FC<Partial<GridTypeMap>> = (props) => {
  const classes = useFooterStyles();

  return (
    <Grid container className={classes.Grid} {...props}/>
  )
};

