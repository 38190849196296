import React, {FC, useEffect, useRef} from "react";
import {setFooterHeightStore} from "common-frontend-components";
import {useFooterStyles} from "../useFooterStyles";


export const FooterContainer:FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = (
  {...props}) =>
{
  const classes = useFooterStyles();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setFooterHeightStore({ footerHeight: (ref && ref.current && ref.current.clientHeight)
      ? ref.current.clientHeight
      : 0
    })
  });

  return (
    <div ref={ref} className={classes.footer} {...props}/>
  )
};
