import { makeStyles } from '@material-ui/core/styles';


export default makeStyles({
  termTitle: {
    marginBottom: 36,
    textAlign: 'center'
  },
  termText: {
    fontWeight: 400
  },
  termBold: {
    fontWeight: 600
  },
  termContainer: {
    maxWidth: '100%',
    overflowX: 'hidden',
    marginTop: 20,
    padding: 40,
  },
  termBox: {
    maxWidth: 600,
    margin: 'auto',
    overflowX: 'hidden',
  }
});